import { Col, Row } from 'react-bootstrap';
import Layout from 'components/layout';
import { Helmet } from "react-helmet";
import React from 'react';
import Slider from '../components/slider';
import agilecrm from 'images/agilecrm.svg';
import copper from 'images/copper.svg';
import hubspot from 'images/hubspot.svg';
import icon from 'images/icon.svg';
import pipedrive from 'images/pipedrive.svg';
import salesforce from 'images/salesforce.svg';
import zoho from 'images/zoho.svg';

const Integration = () => {
    return (
        <Layout>
            <div>
                <Helmet>
                    <link rel="icon" href={icon} />
                    <title>We integrate will most tools used by Sales</title>
                    <meta name="description" content="Among all tools used by Sales team, CRM is the one tool where sales reps spend most time (62%), followed by Email Suite, and Video Conference Platforms." />
                </Helmet>
                <div className="home">
                    <div>
                        <p className="title">We integrate with most tools used by Sales</p>
                        <p className="description">FACT: Among all tools used by Sales team, CRM is the one tool where sales reps spend most time (62%), followed by Email Suite, and Video Conference Platforms.</p>
                    </div>
                </div>
                <Row style={{ justifyContent: 'center', marginBottom: '100px', marginTop: '50px' }}>
                    <div style={{ width: '80%', background: "rgba(196, 101, 255, 0.3)", justifyContent: 'center', textAlign: 'center', borderRadius: '20px' }}>
                        <p style={{ color: '#5958C7', fontFamily: 'Inria Sans', fontSize: '40px', fontWeight: 'bold' }}>Integrations</p>
                        <Row style={{ height: '250px' }}>
                            <Col xs={12} md={6}>
                                <img src={salesforce} style={{ width: '80%', height: '125px' }} alt="something went Wrong!" />
                            </Col>
                            <Col xs={12} md={6}>
                                <img src={zoho} style={{ width: '80%', height: '125px' }} alt="something went Wrong!" />
                            </Col>
                        </Row>
                        <Row style={{ height: '300px' }}>
                            <Col xs={12} md={6}>
                                <img src={hubspot} style={{ width: '80%', height: '125px' }} alt="something went Wrong!" />
                            </Col>
                            <Col xs={12} md={6}>
                                <img src={copper} style={{ width: '80%', height: '125px' }} alt="something went Wrong!" />
                            </Col>
                        </Row>
                        <Row style={{ height: '250px' }}>
                            <Col xs={12} md={6}>
                                <img src={pipedrive} style={{ width: '80%', height: '125px' }} alt="something went Wrong!" />
                            </Col>
                            <Col>
                                <img src={agilecrm} style={{ width: '80%', height: '125px' }} alt="something went Wrong!" />
                            </Col>
                        </Row>
                    </div>
                </Row>
                <div>
                    <p className='heading'>Hear from our clients</p>
                </div>
                <Slider />
            </div >
        </Layout>
    )
};

export default Integration;
